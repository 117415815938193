const nlNl = {
    global: {
        registration: "Registratie",
        emptyFields: "Velden legen",
        create: "Aanmaken",
        complaintType: "Klacht type",
        bookDown: "Afboeken",
        bookUp: "Opboeken",
        report: "Melden",
        barcodeProduct: "Barcode product",
        complaintReason: "Klacht reden",
        chooseReason: "Kies een reden",
        price: "Prijs",
        color: "Kleur",
        size: "Maat",
        articleNumberShort: "Artikelnr.",
        colorVendorShort: "Kleur lev.",
        articleNumberVendorShort: "Artikelnr. lev.",
        barcode: "Barcode",
        noProductFound: "Geen product gevonden.",
        complaintConfirmTitle: "Weet u zeker dat u een klacht wilt melden?",
        cancel: "Annuleren",
        complaintPictureTitle: "Foto van de klacht",
        customerDataNote: "<strong>Let op:</strong>&nbsp; wij maken u er op attent dat u enkel 'eenvoudige' gegevens mag vragen aan uw bezoeker. Kiest u er voor om gevoelige informatie vast te leggen zoals klant gegevens e.d., dan dient u dat duidelijk te communiceren.",
        addPicture: "Foto toevoegen",
        xPicturesAdded: (amount) => `${amount} foto('s) toegevoegd`,
        complaintsList: "Klachtenlijst",
        xComplaintsFound: (amount) => `${amount} klachten gevonden`,
        product: "Product",
        date: "Datum",
        type: "Type",
        reason: "Reden",
        pictures: "Foto('s)",
        picture: "Foto",
        save: "Opslaan",
        noPictures: "Geen foto's"
    },
    navigation: {
        registration: "Registratie",
        complaints: "Klachtenlijst"
    },
    messages: {
        loading: {
            default: "Laden...",
            complaintsListLoading: "Klachtenlijst wordt geladen..."
        },
        validation: {
            noComplaintTypeSelected: "Er is geen klacht type geselecteerd.",
            noComplaintReasonSelected: "Er is geen klacht reden geselecteerd.",
            noComplaintProductFound: "Er is geen product gevonden om een klacht over aan te maken."
        },
        error: {
            noBranchError: "Let op: Er is iets fout gegaan bij het ophalen van de vestigingen. Hierdoor is er geen koppeling aan een vestiging gevonden en kunt u <b>geen</b> klachten aanmaken of inzien.",
            complaintCreationError: "Er is iets fout gegaan bij het aanmaken van uw klacht.",
            pictureAdditionError: "Er is iets fout gegaan bij het toevoegen van de afbeelding aan de klacht.",
            invalidFileExtension: "Dit bestandstype is niet toegestaan. Upload een foto of video."
        },
        success: {
            complaintCreated: "De klacht is succesvol aangemaakt.",
            pictureAdded: "De foto is toegevoegd aan de klacht."
        },
        warning: {
            noAppRightsUnlinkedBranch: "Let op: U bent niet gekoppeld aan een vestiging, hierdoor kunt u <b>geen</b> klachten aanmaken of inzien.",
        }
    },
};

export default nlNl;
