import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { sharedRoutes } from "./sharedRoutes";

const Registration = lazy(() => import("pages/Registration"));
const Complaints = lazy(() => import("pages/Complaints"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));

export const privateRoutes = [
    {
        key: "root",
        element: <Outlet />,
        children: [
            {
                key: "registration",
                path: "/",
                title: "navigation.registration",
                index: true,
                inMenu: true,
                matchEnd: true,
                scopes: [],
                roles: [],
                element: <Suspense fallback={<></>}><Registration /></Suspense>,
                children: [],
            },
            {
                key: "complaints",
                path: "/complaints",
                title: "navigation.complaints",
                inMenu: true,
                matchEnd: false,
                scopes: [],
                roles: [],
                element: <Suspense fallback={<></>}><Complaints /></Suspense>,
                children: [],
            },
            {
                key: "profile",
                path: "/profile",
                inMenu: false,
                scopes: [],
                roles: [],
                element: <Suspense fallback={<></>}><ProfilePage /></Suspense>,
                children: [],
            },
            ...sharedRoutes
        ]
    }
]