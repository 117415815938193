const deDe = {
    global: {
        registration: "Anmeldung",
        emptyFields: "Leerfelder",
        create: "Schaffen",
        complaintType: "Beschwerde Typ",
        bookDown: "abschreiben",
        bookUp: "buchen",
        report: "Bericht",
        barcodeProduct: "Barcode-Produkt",
        complaintReason: "Beschwerdegrund",
        chooseReason: "Wähle einen Grund",
        price: "Preis",
        color: "Farbe",
        size: "Kamerad",
        articleNumberShort: "Artikelnummer.",
        colorVendorShort: "Farbstufe.",
        articleNumberVendorShort: "Artikel-Nr. lev.",
        barcode: "Barcode",
        noProductFound: "Kein Produkt gefunden.",
        complaintConfirmTitle: "Sind Sie sicher, dass Sie eine Beschwerde melden möchten?",
        cancel: "Absagen",
        complaintPictureTitle: "Foto der Beschwerde",
        customerDataNote: "<strong>Hinweis:</strong> Wir möchten Sie darauf hinweisen, dass Sie nur 'einfache' Daten von Ihrem Besucher anfordern dürfen. Wenn Sie sich dafür entscheiden, sensible Informationen wie Kundendaten usw. aufzuzeichnen, müssen Sie dies mitteilen deutlich.",
        addPicture: "Foto hinzufügen",
        xPicturesAdded: (amount) => `${amount} Fotos hinzugefügt`,
        complaintsList: "Reklamationsliste",
        xComplaintsFound: (amount) => `${amount} Beschwerden gefunden`,
        product: "Produkt",
        date: "Datum",
        type: "Typ",
        reason: "Ritt",
        pictures: "Fotos",
        picture: "Foto",
        save: "Speichern",
        noPictures: "Keine fotos gefunden"
    },
    navigation: {
        registration: "Anmeldung",
        complaints: "Beschwerdeliste"
    },
    messages: {
        loading: {
            default: "Belastung...",
            complaintsListLoading: "Beschwerdeliste wird geladen..."
        },
        validation: {
            noComplaintTypeSelected: "Es wurde kein Beschwerdetyp ausgewählt.",
            noComplaintReasonSelected: "Es wurde kein Reklamationsgrund ausgewählt.",
            noComplaintProductFound: "Es wurde kein Produkt gefunden, das beanstandet werden könnte."
        },
        error: {
            noBranchError: "Hinweis: Beim Abrufen der Filialen ist etwas schief gelaufen. Als Ergebnis wurde kein Link zu einer Filiale gefunden und Sie können <b>keine</b> Beschwerden erstellen oder anzeigen.",
            complaintCreationError: "Beim Erstellen Ihrer Beschwerde ist etwas schief gelaufen.",
            pictureAdditionError: "Beim Hinzufügen des Bildes zur Beschwerde ist etwas schief gelaufen.",
            invalidFileExtension: "Dieser Dateityp ist nicht zulässig. Laden Sie ein Foto oder Video hoch."
        },
        success: {
            complaintCreated: "Die Beschwerde wurde erfolgreich erstellt.",
            pictureAdded: "Das Foto wurde der Beschwerde hinzugefügt."
        },
        warning: {
            noAppRightsUnlinkedBranch: "Hinweis: Sie sind mit keiner Einrichtung verknüpft, daher können Sie keine Beschwerden erstellen oder anzeigen."
        }
    },
};

export default deDe;
