import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from "@divide/ois-react-components";
import { useGetUserBranch } from "@divide/retailsuite-react-hooks";
import appConfig from "constants/appConfig";

export default function useBranchWarning(translation) {
    const userBranch = useGetUserBranch(useAuth0, appConfig);

    useEffect(() => {
        let status = userBranch?.status;
        let translationKey;

        if (status === "HAS_NO_BRANCH") {
            translationKey = "messages.warning.noAppRightsUnlinkedBranch";
        } else if (status === "ERROR") {
            translationKey = "messages.error.noBranchError";
        }

        if (translationKey) {
            toast.warn(<p className="toast-content-encompass">{translation(translationKey)}</p>, {
                position: "top-left",
                autoClose: false,
                draggable: false,
                pauseOnFocusLoss: false,
            });
        }
    }, [userBranch]);
}
