import { sharedStorageKeys } from "@divide/retailsuite-react-framework/constants";

const storageKeys = {
    date: "pc_date",
    version: "pc_version",
    cdnDomain: "pc_cdn-domain",
    refreshTime: "pc_refresh-time",
    addToHomeScreenMessageIsClosed: "pc_add-to-home-screen-message-is-closed",
    hasUsedHomeScreenAppMessagesIsClosed: "pc_has-used-home-screen-message-is-closed",
    hasUsedHomeScreenApp: "pc_has-used-home-screen-app",
    updateAvailable: "pc_update_available",
    ...sharedStorageKeys
};

export default storageKeys;