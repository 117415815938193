const enGb = {
    global: {
        registration: "Registration",
        emptyFields: "Empty fields",
        create: "Create",
        complaintType: "Complaint type",
        bookDown: "write off",
        bookUp: "book up",
        report: "Report",
        barcodeProduct: "Product barcode",
        complaintReason: "Complaint reason",
        chooseReason: "Pick a reason",
        price: "Price",
        color: "Color",
        size: "Size",
        articleNumberShort: "Article no.",
        colorVendorShort: "Color vend.",
        articleNumberVendorShort: "Article no. vend.",
        barcode: "Barcode",
        noProductFound: "No product found.",
        complaintConfirmTitle: "Are you sure you want to report a complaint?",
        cancel: "Cancel",
        complaintPictureTitle: "Photo of the Complaint",
        customerDataNote: "<strong>Note:</strong>&nbsp; we would like to point out that you may only request 'simple' data from your visitor. If you choose to record sensitive information you must communicate that clearly.",
        addPicture: "Add picture",
        xPicturesAdded: (amount) => `${amount} picture(s) added`,
        complaintsList: "Complaints list",
        xComplaintsFound: (amount) => `${amount} complaint(s) found`,
        product: "Product",
        date: "Date",
        type: "Type",
        reason: "Reason",
        pictures: "Picture(s)",
        picture: "Picture",
        save: "Save",
        noPictures: "No pictures"
    },
    navigation: {
        registration: "Registration",
        complaints: "Complaints list"
    },
    messages: {
        loading: {
            default: "Loading...",
            complaintsListLoading: "Complaints list is being loaded..."
        },
        validation: {
            noComplaintTypeSelected: "No complaint type has been selected.",
            noComplaintReasonSelected: "No complaint reason has been selected.",
            noComplaintProductFound: "No product has been found to make a complaint about."
        },
        error: {
            noBranchError: "Note: Something went wrong while retrieving the locations. As a result, no link to a location was found and you cannot create a complaint.",
            complaintCreationError: "Something went wrong while creating your complaint.",
            pictureAdditionError: "Something went wrong while adding the image to the complaint.",
            invalidFileExtension: "This file type is not allowed. Please upload a photo or video."
        },
        success: {
            complaintCreated: "The complaint has been successfully created.",
            pictureAdded: "The photo has been added to the complaint."
        },
        warning: {
            noAppRightsUnlinkedBranch: "Note: You are not linked to an establishment, so you cannot create a complaint.",
        },
    },
};

export default enGb;
