const frFr = {
    global: {
        registration: "Inscription",
        emptyFields: "Champs vides",
        create: "Créer",
        complaintType: "Type de réclamation",
        bookDown: "Écrire",
        bookUp: "Réserver",
        report: "Rapporter",
        barcodeProduct: "Produit code-barres",
        complaintReason: "Motif de la réclamation",
        chooseReason: "Choisissez une raison",
        price: "Prix",
        color: "Couleur",
        size: "Camarade",
        articleNumberShort: "N° d'article.",
        colorVendorShort: "Niveau de couleur.",
        articleNumberVendorShort: "Article n° lev.",
        barcode: "Code à barre",
        noProductFound: "Aucun produit trouvé.",
        complaintConfirmTitle: "Etes-vous sûr de vouloir signaler une réclamation ?",
        cancel: "Annuler",
        complaintPictureTitle: "Photo de la plainte",
        customerDataNote: "<strong>Remarque :</strong>&nbsp; nous attirons votre attention sur le fait que vous ne pouvez demander que des données 'simples' à votre visiteur. Si vous choisissez d'enregistrer des informations sensibles telles que des données client, etc., vous devez le communiquer clairement.",
        addPicture: "Ajouter une photo",
        xPicturesAdded: (amount) => `${amount} photos ajoutées`,
        complaintsList: "Klachtenlijst",
        xComplaintsFound: (amount) => `${amount} plaintes trouvées`,
        product: "Produit",
        date: "Date",
        type: "Taper",
        reason: "Rodé",
        pictures: "Photos",
        picture: "Photo",
        save: "Sauvegarder",
        noPictures: "Aucune photo"
    },
    navigation: {
        registration: "Inscription",
        complaints: "Liste des plaintes"
    },
    messages: {
        loading: {
            default: "Charger...",
            complaintsListLoading: "Chargement de la liste des réclamations..."
        },
        validation: {
            noComplaintTypeSelected: "Aucun type de plainte n'a été sélectionné.",
            noComplaintReasonSelected: "Aucun motif de plainte n'a été sélectionné.",
            noComplaintProductFound: "Aucun produit n'a été trouvé pour déposer une réclamation."
        },
        error: {
            noBranchError: "Remarque: Une erreur s'est produite lors de la récupération des branches. Par conséquent, aucun lien vers une branche n'a été trouvé et vous ne pouvez <b>pas</b> créer ou afficher des réclamations.",
            complaintCreationError: "Une erreur s'est produite lors de la création de votre plainte.",
            pictureAdditionError: "Une erreur s'est produite lors de l'ajout de l'image à la plainte.",
            invalidFileExtension: "Ce type de fichier n'est pas autorisé. Téléchargez une photo ou une vidéo."
        },
        success: {
            complaintCreated: "La plainte a été créée avec succès.",
            pictureAdded: "La photo a été ajoutée à la plainte."
        },
        warning: {
            noAppRightsUnlinkedBranch: "Remarque: Vous n'êtes pas lié à un établissement, vous ne pouvez donc pas créer ou afficher des réclamations.",
        }
    },
};

export default frFr;
